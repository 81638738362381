import React, { useState } from "react";
import { Link } from "gatsby-theme-material-ui";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SEO from "../components/seo";

import "./userProfile.scss";

const userProfile = (props) => {
  return (
    <React.Fragment>
      <SEO title={props.data.contentfulAuthor.name} />
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} className="text-center">
            <Box className="profile-block">
              {props.data.contentfulAuthor.profilePhoto ? (
                <img
                  src={props.data.contentfulAuthor.profilePhoto.file.url}
                  alt=""
                />
              ) : (
                <img
                  src="https://images.ctfassets.net/iyqxhlk14jb8/4zo0lWV0x7EKtTrypYAIay/2435a984ffc7013b3484521a080f7e77/default-avatar.png"
                  alt="user avatar"
                />
              )}
              <Typography variant="h1">
                {props.data.contentfulAuthor.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} className="author-content">
            <Box>
              <Typography variant="overline" component="h2">
                {props.data.allContentfulTrip.totalCount} Journeys
              </Typography>
            </Box>
            {props.data.allContentfulTrip.edges.map((edge) => (
              <article>
                <Box className="content-item" key={edge.node.id}>
                  <Box className="content-item-info">
                    <Box className="content-item-meta">
                      <Box component="span">
                        {edge.node.category && (
                          <div>
                            <Box component="span">{edge.node.category} </Box>
                          </div>
                        )}
                      </Box>
                      <Box component="span">
                        {edge.node.level && (
                          <div>
                            <Box component="span" className="separator">
                              &#183;
                            </Box>
                            <Box component="span">{edge.node.level}</Box>
                          </div>
                        )}
                      </Box>
                    </Box>
                    <Box className="content-item-title">
                      <Typography variant="body1" component="h3">
                        <Link
                          to={`/app/unpack/${edge.node.slug}`}
                          color="secondary"
                        >
                          {edge.node.title}
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </article>
            ))}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default userProfile;

export const query = graphql`
  query($id: String!) {
    contentfulAuthor(id: { eq: $id }) {
      name
      profilePhoto {
        file {
          url
        }
      }
    }

    allContentfulTrip(filter: { author: { id: { eq: $id } } }) {
      totalCount
      edges {
        node {
          id
          slug
          category
          title
          level
        }
      }
    }
  }
`;
